'use client';

import { IdsCol, IdsContainer, IdsRow } from '@emergn-infinity/ids-react';

import LogoHeader from '@/components/logo-header/logo-header';

import styles from './layout.module.scss';

const LayoutContainer = ({ children }: { children: React.ReactNode }) => {
  return (
    <div className={styles.SignIn}>
      <IdsContainer fixedWidthSize="xl" fullHeight>
        <div className={styles.SignInWrapper}>
          <LogoHeader />
          <div className={styles.SignInMain}>
            <IdsRow horizontalAlign="center">
              <IdsCol xs="12" sm="10" md="8" lg="6">
                {children}
              </IdsCol>
            </IdsRow>
          </div>
        </div>
      </IdsContainer>
    </div>
  );
};

export default LayoutContainer;
