import React from 'react';
import { IdsButton, IdsLink, IdsText } from '@emergn-infinity/ids-react';

import { usePathname } from 'next/navigation';

import { AppRoutes, PRAXIS_MARKETING_WEBSITE } from '@/constants/general';
import { PraxisLogo } from '../../../public/images';

import styles from './logo-header.module.scss';

export enum PageOptions {
  SignIn = '/sign-in',
  SignUp = '/sign-up',
  ForgotPassword = '/forgot-password',
  PasswordSent = '/password-sent',
}

const LogoHeader = () => {
  const page = usePathname();

  let label: string = '';
  let ctaLabel: string = '';
  let ctaRoute = AppRoutes.SignIn;

  switch (page) {
    case PageOptions.SignIn: {
      label = 'Not a member?';
      ctaLabel = 'Register here';
      ctaRoute = AppRoutes.SignUp;
      break;
    }
    case PageOptions.SignUp: {
      label = 'Already a member?';
      ctaLabel = 'Sign in';
      break;
    }
    case PageOptions.ForgotPassword:
    case PageOptions.PasswordSent: {
      ctaLabel = 'Back to sign in';
      break;
    }
  }

  return (
    <div className={styles.LogoHeader}>
      <IdsLink href={PRAXIS_MARKETING_WEBSITE} target="_blank" customClasses={styles.LogoHeaderLogo}>
        <PraxisLogo />
      </IdsLink>
      {ctaLabel && (
        <div className={styles.LogoHeaderCta}>
          {label && <IdsText size="sm">{label}</IdsText>}
          <IdsButton themeName="vfq" variant="secondary" color="neutral" href={`/app${ctaRoute}`}>
            <>{ctaLabel}</>
          </IdsButton>
        </div>
      )}
    </div>
  );
};

export default LogoHeader;
